<template>
  <div>
    <b-modal
      v-model="showModal"
      hide-header-close
      centered
      no-close-on-backdrop
    >
      <b-container fluid style="text-align: center"
        ><img
          class="modal-image"
          :src="
            imgSrc
              ? `data:image/png;base64,${imgSrc}`
              : require('../../assets/Avatar.png')
          "
          alt="Profile Image"
      /></b-container>

      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-primary"
          @click="$emit('closedModal')"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";

export default {
  components: { BModal, BButton },

  props: {
    imgSrc: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      showModal: true,
    };
  },
};
</script>

<style scoped>
.modal-image {
  max-width: 100%;
  max-height: 60vh;
}
</style>
